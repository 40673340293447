import styled, { css } from "styled-components";
import { breakpoints, leerColor, colores } from "../../../constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  a {
    text-decoration: none;
  }

  .subtitulo-historial {
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
  }

  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    //margin: 20px 0 20px 0;
  }
  .actualizacion-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .buttons-cont {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      gap: 10px;
    }
    h4 {
      font-size: 16px;
      font-weight: 500;
      color: ${({ theme }) => theme.title};
      margin: 0;
    }
    p {
      margin: 0;
      font-size: 14px;
      color: ${({ theme }) => theme.subtitle};
    }
  }

  .premios-botones-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  p {
    margin: 0 0 0px 0;
  }

  h3 {
    margin-top: 0;
    color: ${leerColor(colores.celeste)};
  }

  h4 {
    margin: 10px 0;
    color: ${({ theme }) => theme.title};
  }

  .container-botones {
    display: flex;
    gap: 10px;
  }

  .data-container {
    padding: 20px 0;
    //border: 1px solid #eaeaea;
    border-radius: 6px;
    box-sizing: border-box;
  }

  .data-icon {
    font-size: 24px;
  }

  .total-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    gap: 10px;
    box-sizing: border-box;
    padding: 10px 20px;
    background-color: ${leerColor(colores.blanco)};

    span {
      font-size: 14px;
      color: ${leerColor(colores.grisEstados)};
    }

    h2 {
      margin: 0;
      font-weight: 500;
      font-size: 24px;
    }

    .link {
      color: ${leerColor(colores.grisEstados)};
      display: flex;
      align-items: center;
      gap: 6px;
      transition: 0.2s;
      cursor: pointer;
    }

    .link:hover {
      color: ${leerColor(colores.azulRebranding)};
    }
  }

  .pie {
    width: 49%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${leerColor(colores.blanco)};
  }

  /* .premio-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    padding: 60px 0;
    gap: 10px;
    color: ${leerColor(colores.negro)};
    font-weight: 500;
    cursor: pointer;
    transition: 0.2;
    font-size: 16px;
    text-align: center;

    :hover {
      opacity: 0.7;
    }
  } */

  .buttons-container {
    display: flex;
    width: 100%;
    gap: 10px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;

    a {
      width: 50% !important;
    }
  }

  .grupo-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.border};
    background-color: ${({ theme }) => theme.background};

    .grupo-titulo-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid ${({ theme }) => theme.border};

      .grupo-titulo-botones {
        display: flex;
        gap: 10px;
        margin-right: 20px;

        .icon {
          color: ${({ theme }) => theme.little_icon};
          font-size: 16px;
          padding: 6px;
          box-sizing: border-box;
          border-radius: 100%;
          border: 1px solid ${leerColor(colores.azulRebranding)};
        }

        span {
          color: ${leerColor(colores.blanco)};
        }
      }

      h3 {
        font-weight: 500;
        color: ${({ theme }) => theme.title};
      }
    }

    .integrantes-container {
      display: flex;
      //border-top: 1px solid #eaeaea;
      flex-direction: column;

      .integrante {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        box-sizing: border-box;
        border-bottom: 1px solid #eaeaea;
        padding: 20px;
        cursor: pointer;
        transition: 0.2s;
      }

      .integrante:last-child {
        border: none;
      }

      .integrante:hover {
        opacity: 0.7;
      }

      .integrante-data-container {
        display: flex;
        gap: 10px;
      }

      .integrante-data {
        display: flex;
        flex-direction: column;
        gap: 6px;
        .nombre {
          font-weight: 500;
          color: ${({ theme }) => theme.title};
        }
        div {
          display: flex;
          gap: 16px;
        }
      }

      .integrante-prima-actual {
        color: ${({ theme }) => theme.subtitle};;
        font-weight: 300;
      }

      .userIcon {
        display: flex;
        border: 1px solid ${({ theme }) => theme.border};
        font-size: 16px;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 100px;
        color: ${({ theme }) => theme.little_icon};
        align-self: flex-start;
      }

      .ganador {
        font-size: 16px;
        color: ${leerColor(colores.naranjaEstados)};
        border: 1px solid ${leerColor(colores.naranjaEstados)};
      }
    }

    h3 {
      font-size: 16px;
      color: ${({ theme }) => theme.title};
      padding: 20px;
      margin: 0;
    }

    span {
      font-size: 14px;
      color: ${({ theme }) => theme.subtitle};
    }

    .header-premio-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid ${({ theme }) => theme.border};
      padding: 20px;

      div {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }

      h3 {
        padding: 0;
        font-weight: 500;
      }

      .chevron-vendedores {
        display: flex;
        align-items: center;
        gap: 6px;
        color: ${leerColor(colores.azulRebranding)};
        cursor: pointer;
        
        .icono-chevron {
          font-size: 20px;
          display: flex;
          color: ${leerColor(colores.azulRebranding)};
        }

        :hover {
          opacity: 0.7;
        }
      }
    }

    .vendedores-container {
      display: flex;
      gap: 20px;
      padding: 20px;
      flex-wrap: wrap;

      .vendedor {
        display: flex;
       // flex-direction: column;
        //justify-content: center;
        align-items: center;
        //text-align: center;
        gap: 10px;
        //border-radius: 8px;
        //border: 1px solid #eaeaea;
        //padding: 20px 0;

        .nombre {
          color: ${({ theme }) => theme.title};
        }

        .userIcon {
            display: flex;
            border: 1px solid #eaeaea;
            font-size: 16px;
            box-sizing: border-box;
            padding: 10px;
            border-radius: 100px;
            color: #1a56ff;
        }
      }

      strong {
        font-weight: 400;
        color: ${leerColor(colores.negro)};
      }
    }
  }

  /*
  .icon {
    font-size: 36px;
    color: ${leerColor(colores.azulRebranding)};
  }
  */

  .descripcion {
    font-size: 14px;
    color: ${({ theme }) => theme.subtitle};
  }

  .vendedor-container {
    border: 1px solid #eaeaea;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 20px;

    h3 {
      color: ${leerColor(colores.negro)};
    }
  }
  .vendedor-container-2 {
    display: flex;
    border: 1px solid ${({ theme }) => theme.border };
    border-radius: 8px;
    box-sizing: border-box;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.background };

    h3 {
      color: ${leerColor(colores.negro)};
    }
  }

  .grid-premios {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }

  .vendedor-info-container {
    width: 20%;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    //background-color: ${leerColor(colores.negro)};

    h3 {
      color: ${({ theme }) => theme.title };
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }
    span {
      font-size: 14px;
      color: ${({ theme }) => theme.subtitle };
    }
    .info-cont {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .grupo {
        font-size: 10px;
        //background-color: ${leerColor(colores.negro)};
        text-transform: uppercase;
        color: ${leerColor(colores.azulRebranding)};
        border: 1px solid ${leerColor(colores.azulRebranding)};
        padding: 4px 6px;
        box-sizing: border-box;
        border-radius: 4px;
        font-weight: 600;
        cursor: pointer;
      }

      a {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    .userIcon {
      display: flex;
      border: 1px solid #eaeaea;
      font-size: 20px;
      box-sizing: border-box;
      padding: 10px;
      border-radius: 100px;
      color: #1a56ff;
    }
  }

  .premio-valores-container-2 {
    width: 80%;
    font-size: 14px;
    color: ${({ theme }) => theme.subtitle };
    border-left: 1px solid ${({ theme }) => theme.border };
    //border-right: 1px solid #eaeaea;
    box-sizing: border-box;
    flex-wrap: wrap;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .premio-valor {
    display: flex;
    gap: 6px;
    flex-direction: column;
    //border-bottom: 1px solid #eaeaea;
    box-sizing: border-box;
    padding: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;

    .premio-label {
      //font-weight: 500;
      color: ${({ theme }) => theme.title };
    }
  }

  .premio-valores-container {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #eaeaea;
    font-size: 14px;
    color: ${leerColor(colores.grisEstados)};

    :last-of-type {
      border: none;
    }
  }

  .total-container {
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 8px;
    gap: 10px;
    box-sizing: border-box;
    padding: 10px 20px;
    background-color: ${({ theme }) => theme.background};
    transition: all 0.5s;

    a {
      color: inherit; /* blue colors for links too */
      text-decoration: inherit; /* no underline */
    }

    span {
      font-size: 14px;
      color: ${({ theme }) => theme.subtitle};
      font-weight: 400;
      transition: all 0.5s;
    }

    h2 {
      margin: 0;
      font-weight: 500;
      font-size: 24px;
      color: ${({ theme }) => theme.title};
      transition: all 0.5s;
    }

    .link {
      color: ${leerColor(colores.grisEstados)};
      display: flex;
      align-items: center;
      gap: 6px;
      transition: 0.2s;
      cursor: pointer;
    }

    .link:hover {
      color: ${leerColor(colores.azulRebranding)};
    }
  }

  a .total-container:hover {
    border: 1px solid ${leerColor(colores.azulRebranding)};
    transition: 0.3s;
  }

  .total {
    border: 1px solid ${({ theme }) => theme.total_card_border};
    color: ${leerColor(colores.blanco)};
    background-color: ${({ theme }) => theme.total_card_background};

    span,
    h2 {
      color: ${leerColor(colores.blanco)};
    }
  }

  .sin-solicitudes-container {
    display: flex;
    flex-direction: column;
    margin: 0;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 60px 0;

    .icon-sin-solicitudes {
    font-size: 60px;
    color: ${leerColor(colores.azulRebranding)};
    display: flex;
  }


    h2 {
      color: ${leerColor(colores.negro)} !important;
      font-weight: 500;
      margin: 0;
    }

    .description {
      color: ${leerColor(colores.grisEstados)};
      font-size: 14px;
    }
  }

  .ver-mas-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${leerColor(colores.azulRebranding)};
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;

    .chevron {
      font-size: 18px;
      transition: 0.3s;
    }

    :hover {
      opacity: 0.7;
    }

    :hover .chevron{
      transform: translateY(4px);
    }
  }


  .section-data {
      background-color: ${leerColor(colores.blanco)};
      border-radius: 8px;
      box-sizing: border-box;
      padding: 20px;
      border: 1px solid #eaeaea;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

  .aclaracion-operaciones {
    font-size: 13px;
    color: ${({ theme }) => theme.subtitle};
    font-weight: 500;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.border};
    box-sizing: border-box;
    padding: 10px 16px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 6px;
    margin: 0px;
    width: fit-content;

    strong {
      color: ${leerColor(colores.rojo)};
      font-weight: 500;
    }

    .icon {
      display: flex;
      align-items: center;
      color: ${leerColor(colores.rojo)};
    }
  }
`;

export const ContainerInfo = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin-top: 60px;
  //border-top: 1px solid #eaeaea;
  padding: 120px 0;

  p {
    margin: 0;
  }

  strong {
    color: ${leerColor(colores.azul)};
  }
`;

export const Icon = styled.span`
  font-size: 60px;
  display: flex;
  color: ${leerColor(colores.celeste)};
`;

export const ModificacionHistorial = styled.div`
  display: flex;
  gap: 10px;

  .icono {

    span {
      display: flex;
    border: 1px solid ${({ theme }) => theme.border};
    font-size: 16px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 100px;
    color: #1a56ff;
    }

  }  

  .modificacion {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .modificacion-hora {
    font-size: 12px;
  }

  span {
    color: ${({ theme }) => theme.subtitle};
    font-size: 14px;

    strong {
      color: ${leerColor(colores.negro)};
      font-weight: 500;
    }

    .vendedor {
        color: ${leerColor(colores.azulRebranding)};
        cursor: pointer;
    }
  }

  .userIcon {
    display: flex;
    border: 1px solid ${({ theme }) => theme.border};
    font-size: 16px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 100px;
    color: #1a56ff;
    margin-right: 8px;
  }

  .calculo-container {
    display: flex;
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 8px;
    box-sizing: border-box;
    padding: 20px;
    gap: 20px;
    line-height: 1.7;
  }
`;
