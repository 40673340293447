import React from "react";
import { Col } from "../Grid";
import Divider from "../Divider";

export const RechazosCard = ({ rechazo }) => {
  return (
    <Col desktop={3}>
      <div className="card-rechazos-container">
        <p>
          <strong>Póliza {rechazo.poliza}</strong>
        </p>
        <Divider />
        {rechazo.detalle ? <p>ID Asegurado: {rechazo.aseg ?? "-"}</p> : null}
        <p>DNI: {rechazo.dni ?? "-"}</p>
        <p>Celular: {rechazo.telefono ?? "-"}</p>
        {rechazo.mail ? <p>{rechazo.mail}</p> : <p>Mail: -</p>}
        {rechazo.detalle ?
        <>
        <Divider />
        <p>
          <strong>Motivo del error</strong>
        </p>
        <p>{rechazo.detalle}</p>
        </>
        : null}
      </div>
    </Col>
  );
};
