import { useEffect, useState } from "react";
import Divider from "../../../components/Divider";
import { Col, Grid } from "../../../components/Grid";
import Layout from "../../../components/Layout";
import NewInner from "../../../components/NewInner";
import NewInputSelect from "../../../components/NewInputSelect";
import CardNumber from "../../../components/CardNumber";
import NewTitlePageSection from "../../../components/NewTitlePageSection";
import NewWrapper from "../../../components/NewWrapper";
import { anios, meses } from "../../../constants/data";
import { Container } from "./styled";
import axiosRequest from "../../../utils/axiosConfig";
import {
  anulacionesComisionesVendedorTable,
  comisionesVendedorTable,
  operacionesComisionesVendedorTable,
} from "../../../utils/tableData";
import NewTable from "../../../components/NewTable";
import { useTable } from "../../../hooks/useTable";
import formatSelectData from "../../../utils/formatSelectData";
import NewButton from "../../../components/NewButton";
import CardSinResultados from "../../../components/CardSinResultados";
import { useLoading } from "../../../hooks/useLoading";
import SpinnerLoading from "../../../components/SpinnerLoading";
import Subtitle from "../../../components/Subtitle";
import { FiDollarSign, FiFile, FiFolder, FiPercent, FiSave, FiSearch, FiUser } from "react-icons/fi";
import { useTabs } from "../../../hooks/useTabs";
import NewTabs from "../../../components/NewTabs";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ModalTotalPorcentajesComisiones from "../../../components/Modals/Comisiones/ModalPorcentajes";
import useModal from "../../../hooks/useModal";
import Modal from "../../../components/Modal";
import Success from "../../../components/Success";
import ModalExito from "../../../components/Modals/General/ModalExito";

const Comisiones = () => {
  const location = useLocation()
  const ven = location.pathname.split("/").length > 2 ? location.pathname.split("/")[2] : null
  const [mes, setMes] = useState(new Date().getMonth());
  const [anio, setAnio] = useState(new Date().getFullYear());
  const [vendedor, setVendedor] = useState(ven ? parseInt(ven) : "");
  const [vendedores, setVendedores] = useState([]);
  const [comisiones, setComisiones] = useState([]);
  const [operaciones, setOperaciones] = useState([]);
  const [anulaciones, setAnulaciones] = useState([]);
  const { order, handleTableOrder } = useTable();
  const { loading, setLoading } = useLoading();
  const { tabSelected, handleTab } = useTabs()
  const loadingComisionesModal = useLoading()
  const userRol = JSON.parse(localStorage.getItem("userInfo"))?.id_rol;
  const [totales, setTotales] = useState({})
  const [totalesAnulaciones, setTotalesAnulaciones] = useState({})
  const formatter = new Intl.NumberFormat("es-ES", {});
  const [tipoVendedor, setTipoVendedor] = useState(null)
  const [teletrabajoActivo, setTeletrabajoActivo] = useState(null)
  const porcentajesModal = useModal()
  const comisionesModal = useModal()

  useEffect(() => {
    const getData = async () => {
      try {
        const vendedores = await axiosRequest.get("/premios/vendedores");
        const vendedoresList = formatSelectData(
          vendedores.data,
          "numero",
          "nombre",
          "numero"
        );
        setVendedores([{ label: "Todos", value: "0", key: 0},...vendedoresList]);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  const handleComisiones = async () => {
    setComisiones([])
    setOperaciones([])
    setAnulaciones([])
    setLoading(true);
    try {
      const comisiones = await axiosRequest.get(`/comisiones/${vendedor}/${mes}/${anio}/${userRol}`);
      const operaciones = await axiosRequest.get(`/comisiones_operaciones/${vendedor}/${mes}/${anio}`);
      const anulaciones = await axiosRequest.get(`/comisiones_anulaciones/${vendedor}/${mes}/${anio}`)
      console.log(comisiones.data)
      setTipoVendedor(comisiones.data.tipo_vendedor)
      setTeletrabajoActivo(comisiones.data.teletrabajoActivo)
      setComisiones(comisiones.data.data)
      setTotales(comisiones.data.totales)
      setAnulaciones(anulaciones.data.anulaciones)
      setTotalesAnulaciones(anulaciones.data.totales)
      setOperaciones(operaciones.data)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const guardarComisiones = async () => {
    loadingComisionesModal.setLoading(true)
    let data = null
    if(comisiones?.length > 0 && comisiones[0]?.data?.length){
      const filterTotalesComisiones = comisiones.map(item => {
        const filterTotal = item.data.filter((_, index) => index === 0)
        const data = filterTotal["0"]
        return data
      })
      data = filterTotalesComisiones.map(item => {
        const findVendedor = vendedores.find(ven => ven.label === item.vendedor)
        return { ...item, id_vendedor: findVendedor?.value, mes: mes, anio: anio }
      })
    } else {
      const vendedor = comisiones[0].vendedor
      const id_vendedor = vendedores.find(ven => ven.label === vendedor)?.value
      const totalComisionesVendedores = { ...comisiones[0], id_vendedor, mes, anio }
      data = totalComisionesVendedores
    }
    try {
      const comisiones = await axiosRequest.post(`/comisiones/${mes}/${anio}`, data);
      if(comisiones.status === 200){
        loadingComisionesModal.setLoading(false)
        comisionesModal.setIsModalOpen(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const tableData = comisionesVendedorTable(tipoVendedor, teletrabajoActivo)
  const operacionesTableData = operacionesComisionesVendedorTable(mes, anio);
  const anulacionesTableData = anulacionesComisionesVendedorTable();

  useEffect(() => {
    if(ven){
      handleComisiones()
    }
  }, [ven])

  const TABS = [
    {
      name: "Comisiones"
    },
    {
      name: "Operaciones"
    },
    {
      name: "Anulaciones"
    },
  ]

  return (
    <>
      {porcentajesModal.isModalOpen ? <ModalTotalPorcentajesComisiones modal={porcentajesModal} mes={mes} anio={anio} refreshData={handleComisiones} /> : null}

      {comisionesModal.isModalOpen  ?
        <ModalExito modal={comisionesModal} />
      : null}

      <Layout>
        <NewWrapper>
          <NewInner>
            <Container>
              <NewTitlePageSection
                title="Comisiones"
                description={ven ? "Tus comisiones, operaciones y anulaciones" : "Lista de comisiones, operaciones y anulaciones de los vendedores"}
              >
                {userRol !== 10 && userRol !== 18 && userRol !== 22 && userRol !== 24  ? <NewButton backgroundColor={true} onClick={() => porcentajesModal.handleModal()}><FiPercent />Gestionar comisiones</NewButton> : null}
              </NewTitlePageSection>
              <Grid colGap={21} rowGap={21} narrow={false}>
                <Col desktop={2}>
                  <NewInputSelect
                    name={"mes"}
                    labelName={"Mes"}
                    onChange={(e) => setMes(e.target.value)}
                    placeholder={"Mes"}
                    options={ven ? meses.filter((mes, index) => index < 2) : meses}
                    value={mes}
                  />
                </Col>
                {!ven ?
                <>
                <Col desktop={2}>
                  <NewInputSelect
                    name={"anio"}
                    labelName={"Año"}
                    onChange={(e) => setAnio(e.target.value)}
                    placeholder={"Año"}
                    options={anios}
                    value={anio}
                  />
                </Col>
                <Col desktop={2}>
                  <NewInputSelect
                    name={"vendedor"}
                    labelName={"Vendedor"}
                    onChange={(e) => setVendedor(e.target.value)}
                    placeholder={"Vendedor"}
                    options={vendedores}
                    value={vendedor}
                  />
                </Col>
                </>
                : null}
                <Col desktop={3} alignBottom={true}>
                  <NewButton backgroundColor={true} disabled={!vendedor} onClick={handleComisiones}>
                    <FiSearch /> Buscar
                  </NewButton>
                </Col>
              </Grid>

              <NewTabs
                  tabs={TABS}
                  tabSelected={tabSelected}
                  handleTab={handleTab}
              />

              {tabSelected === 0 ?
                <Grid colGap={21} rowGap={21} narrow={false}>
                <Col desktop={10}>
                  <Subtitle
                    icon={<FiDollarSign />}
                    title={"Comisiones"}
                    description={`Resultados sobre comisiones de ${meses.find(month => month.value == mes)?.label} ${anio}`}
                    disableDivider={true}
                  />
                </Col>
                {userRol !== 10 && userRol !== 18 && userRol !== 22 && userRol !== 24 ?
                <Col desktop={2}>
                <NewButton backgroundColor={false} textColor={true} borderColor={true} disabled={!vendedor} onClick={guardarComisiones}>{loadingComisionesModal.loading ? "Guardando datos..." : "Guardar comisiones"}</NewButton>
                </Col>
                : null}
                {comisiones?.length > 0 && comisiones[0]?.data?.length > 0 ? comisiones.map(vendedor => {

                  const tableColumns = comisionesVendedorTable(vendedor.tipo_vendedor, vendedor.teletrabajoActivo)
                  return (
                    <>
                    <Col desktop={12}>
                      <Subtitle
                        icon={<FiUser />}
                        title={vendedor.data[0].vendedor}
                        description={`Lista de todos los datos obtenidos para comisiones del vendedor`}
                        disableDivider={true}
                      />
                    </Col>
                    <Col desktop={12}>
                    <NewTable
                      columns={tableColumns}
                      data={vendedor.data}
                      loading={loading}
                      order={handleTableOrder}
                      orderParams={order}
                      spaced={true}
                    />
                  </Col>
                  </>
                  )
                }) : 
                <Col desktop={12}>
                <NewTable
                  columns={tableData}
                  data={comisiones}
                  loading={loading}
                  order={handleTableOrder}
                  orderParams={order}
                  spaced={true}
                />
              </Col>
                }
                {Object.keys(totales)?.length > 0 ? 
                <>
                <Col desktop={12}>
                  <Subtitle
                    icon={<FiDollarSign />}
                    title={"Totales"}
                    description={`Sumas para comisiones, prima teletrabajo y total teletrabajo`}
                    disableDivider={true}
                  />
                </Col>
                  <Col desktop={3}>
                    <CardNumber title={"Total Comisiones"} value={totales.comisiones} />
                  </Col>
                  <Col desktop={3}>
                    <CardNumber title={"Prima Teletrabajo"} value={totales.prima_teletrabajo} />
                  </Col>
                  <Col desktop={3}>
                    <CardNumber title={"Total Teletrabajo"} value={totales.total_teletrabajo} />
                  </Col>
                </>
                : null}
              </Grid>
              : null}

              {tabSelected === 1 ?
                <Grid colGap={21} rowGap={21} narrow={false}>
                <Col desktop={12}>
                  <Subtitle
                    icon={<FiFile />}
                    title={"Operaciones"}
                    description={`Lista de operaciones realizadas`}
                    disableDivider={true}
                  />
                </Col>

                <Col desktop={12}>
                  <NewTable
                    columns={operacionesTableData}
                    data={operaciones}
                    loading={loading}
                    order={handleTableOrder}
                    orderParams={order}
                  />
                </Col>
              </Grid>
              : null}

              {tabSelected === 2 ? (
                <Grid colGap={21} rowGap={21} narrow={false}>
                  <Col desktop={12}>
                    <Subtitle
                      icon={<FiFolder />}
                      title={"Anulaciones"}
                      description={`Lista de anulaciones`}
                      disableDivider={true}
                    />
                  </Col>

                  <Col desktop={12}>
                    <NewTable
                      columns={anulacionesTableData}
                      data={anulaciones}
                      loading={loading}
                      order={handleTableOrder}
                      orderParams={order}
                    />
                  </Col>

                  {Object.keys(totalesAnulaciones)?.length > 0 ? 
                <>
                <Col desktop={12}>
                  <Subtitle
                    icon={<FiDollarSign />}
                    title={"Totales"}
                    description={`Sumas de cantidad de operaciones y primas anuladas`}
                    disableDivider={true}
                  />
                </Col>
                  <Col desktop={3}>
                    <CardNumber title={"Total Operaciones"} value={totalesAnulaciones.total_operaciones} />
                  </Col>
                  <Col desktop={3}>
                    <CardNumber title={"Total Prima Anulada"} value={formatter.format(totalesAnulaciones.total_prima)} />
                  </Col>
                </>
                : null}
                </Grid>
              ) : null}
            </Container>
          </NewInner>
        </NewWrapper>
      </Layout>
    </>
  );
};

export default Comisiones;
