import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Sidebar from "../../../components/Sidebar";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Container } from "./styled";
import {
  FiArrowLeft,
  FiEdit3,
  FiFile,
  FiPlus,
  FiRefreshCcw,
  FiShare,
  FiUploadCloud,
  FiX,
} from "react-icons/fi";
import TitlePageSection from "../../../components/TitlePageSection";
import { useForm } from "../../../hooks/useForm";
import {
  cargarSiniestrosReglas,
  subirRechazoManualReglas,
} from "../../../utils/formRules";
import NewButton from "../../../components/NewButton";
import {
  cargarSiniestrosForm,
  subirRechazoManualForm,
} from "../../../utils/formInitialValues";
import useSiniestros from "../../../hooks/useSiniestros";
import {
  getPolizaInformation,
  getProductoSiniestroId,
} from "../../../services/Siniestros";
import { useLoading } from "../../../hooks/useLoading";
import BackButton from "../../../components/BackButton";
import CrearSiniestroForm from "../../../components/SiniestrosForms/CrearSiniestro";
import Divider from "../../../components/Divider";
import { useTabs } from "../../../hooks/useTabs.js";
import NewTabs from "../../../components/NewTabs/index.jsx";
import NewInputSelect from "../../../components/NewInputSelect/index.jsx";
import { useEffect, useState } from "react";
import {
  getCompanias,
  getEstados,
  getInforPolizas,
  sendData,
  updateMultipleRejects,
  sendMailManually,
  getOperadores,
  getSecciones,
  getMediosPago
} from "../../../services/Rechazos.js";
import { Col, Grid } from "../../../components/Grid/index.js";
import Subtitle from "../../../components/Subtitle/index.jsx";
import InputFile from "../../../components/InputFile/index.js";
import formaterObject from "../../../functions/formaterJson.js";
import useModal from "../../../hooks/useModal.js";
import ModalCargarRechazos from "../../../components/Modals/Rechazos/ModalCargarRechazos/index.js";
import { useAuthContext } from "../../../context/AuthContext.js";
import NewInputBasic from "../../../components/NewInputBasic";
import * as XLSX from "xlsx";
import ModalCargarRechazos2 from "../../../components/Modals/Rechazos/ModalCargarRechazos2/index.js";
import ErrorInput from "../../../components/ErrorInput/index.js";
import { subirRechazoManualUpdateReglas } from "../../../utils/formRules";
import { subirRechazoManualUpdateForm } from "../../../utils/formInitialValues";
import { FiUserCheck } from "react-icons/fi";
import NewWrapper from "../../../components/NewWrapper/index.js";
import NewInner from "../../../components/NewInner/index.js";
import Layout from "../../../components/Layout/index.js";
import NewTitlePageSection from "../../../components/NewTitlePageSection/index.js";

const GestionRechazos = () => {
  const { tabSelected, tabName, handleTab } = useTabs();
  const { authState } = useAuthContext();
  const { userInfo } = authState;
  const { loading, setLoading, loadingText, changeLoadingText } = useLoading();
  const modalCarga = useModal();

  const [companias, setCompanias] = useState([]); //metadatos
  const [estados, setEstados] = useState([]); //metadatos
  const [selection, setSelection] = useState([]); // rechazos cargados en con check
  const [sections, setSections] = useState([]); // el back envia la respuesta por secciones 'nuevos', 'no encontrados' etc
  const [idCompania, setIdCompania] = useState(null);
  const [idNuevoEstado, setIdNuevoEstado] = useState(null);
  const [medioContacto, setmedioContacto] = useState(null);
  const [idFormato, setIdFormato] = useState(1);
  const [lengthFile, setLengthFile] = useState(0);
  const [errorFormatingRows, setErrorsFormatingRows] = useState([]);
  const [modo, setModo] = useState(null);
  const [fileDataToSend, setFileDataToSend] = useState([]);
  const [errorGeneral, setErrorGeneral] = useState(null);
  const [success, setSuccess] = useState(false)
  const [operadorAsignado, setOperadorAsignado] = useState(null);
  const [operadoresBack, setOperadoresBack] = useState([]);
  const [cargaManualCompleta, setCargaManualCompleta] = useState(false)
  const [ramos, setRamos] = useState([]) // ramos/secciones
  const [mediosPago, setMediosPago] = useState([])

//   const dataRechazos = {
//     "cargados": {
//         "nuevos_rechazos": {
//             "descripcion": "Nuevos rechazos cargados a la base de datos",
//             "data": [
//                 {
//                     "id_estado": 8,
//                     "veces_notificado": 0,
//                     "veces_notificado_whatsapp": 0,
//                     "veces_notificado_telefono": 0,
//                     "id": 48513,
//                     "nombre": "GERMAN EZEQUIEL",
//                     "ramo": "AUTOMOTORES",
//                     "poliza": 10583620,
//                     "importe": 232399.91,
//                     "mail": "germanespindola00@gmail.com",
//                     "id_compania": 61,
//                     "id_user": "66",
//                     "id_seccion": 4,
//                     "id_medio_pago": 0,
//                     "telefono": "1122680735",
//                     "dni": "35113696",
//                     "id_carga_masiva": 1739465160803,
//                     "producto": 3,
//                     "id_operador_asignado": 286,
//                     "updatedAt": "2025-02-13T16:46:00.805Z",
//                     "createdAt": "2025-02-13T16:46:00.805Z"
//                 },
//                 {
//                     "id_estado": 8,
//                     "veces_notificado": 0,
//                     "veces_notificado_whatsapp": 0,
//                     "veces_notificado_telefono": 0,
//                     "id": 48514,
//                     "nombre": "JOSE ANTONIO",
//                     "ramo": "AUTOMOTORES",
//                     "poliza": 10635349,
//                     "importe": 309828.99,
//                     "mail": "marcecoco2005@yahoo.com.ar",
//                     "id_compania": 61,
//                     "id_user": "66",
//                     "id_seccion": 4,
//                     "id_medio_pago": 0,
//                     "telefono": "1133812345",
//                     "dni": "21015965",
//                     "id_carga_masiva": 1739465160803,
//                     "producto": 3,
//                     "id_operador_asignado": 286,
//                     "updatedAt": "2025-02-13T16:46:00.822Z",
//                     "createdAt": "2025-02-13T16:46:00.822Z"
//                 },
//                 {
//                     "id_estado": 8,
//                     "veces_notificado": 0,
//                     "veces_notificado_whatsapp": 0,
//                     "veces_notificado_telefono": 0,
//                     "id": 48515,
//                     "nombre": "JULIO LEONARDO",
//                     "ramo": "AUTOMOTORES",
//                     "poliza": 10638221,
//                     "importe": 155155.63,
//                     "mail": "julio_2010_z@hotmail.com",
//                     "id_compania": 61,
//                     "id_user": "66",
//                     "id_seccion": 4,
//                     "id_medio_pago": 0,
//                     "telefono": "1168544112",
//                     "dni": "29144765",
//                     "id_carga_masiva": 1739465160803,
//                     "producto": 3,
//                     "id_operador_asignado": 286,
//                     "updatedAt": "2025-02-13T16:46:00.828Z",
//                     "createdAt": "2025-02-13T16:46:00.828Z"
//                 },
//                 {
//                     "id_estado": 8,
//                     "veces_notificado": 0,
//                     "veces_notificado_whatsapp": 0,
//                     "veces_notificado_telefono": 0,
//                     "id": 48516,
//                     "nombre": "JAVIER ALEJANDRO",
//                     "ramo": "RIESGOS VARIOS",
//                     "poliza": 171585,
//                     "importe": 14314.73,
//                     "mail": "dgomez@vgpower.com.ar",
//                     "id_compania": 61,
//                     "id_user": "66",
//                     "id_seccion": 7,
//                     "id_medio_pago": 0,
//                     "telefono": "1565555303",
//                     "dni": "32714950",
//                     "id_carga_masiva": 1739465160803,
//                     "producto": 21,
//                     "id_operador_asignado": 286,
//                     "updatedAt": "2025-02-13T16:46:00.835Z",
//                     "createdAt": "2025-02-13T16:46:00.835Z"
//                 },
//                 {
//                     "id_estado": 8,
//                     "veces_notificado": 0,
//                     "veces_notificado_whatsapp": 0,
//                     "veces_notificado_telefono": 0,
//                     "id": 48517,
//                     "nombre": "JAVIER ALEJANDRO",
//                     "ramo": "RIESGOS VARIOS",
//                     "poliza": 171587,
//                     "importe": 7157.73,
//                     "mail": "dgomez@vgpower.com.ar",
//                     "id_compania": 61,
//                     "id_user": "66",
//                     "id_seccion": 7,
//                     "id_medio_pago": 0,
//                     "telefono": "1565555303",
//                     "dni": "32714950",
//                     "id_carga_masiva": 1739465160803,
//                     "producto": 21,
//                     "id_operador_asignado": 286,
//                     "updatedAt": "2025-02-13T16:46:00.841Z",
//                     "createdAt": "2025-02-13T16:46:00.841Z"
//                 },
//                 {
//                     "id_estado": 8,
//                     "veces_notificado": 0,
//                     "veces_notificado_whatsapp": 0,
//                     "veces_notificado_telefono": 0,
//                     "id": 48518,
//                     "nombre": "JAVIER ALEJANDRO",
//                     "ramo": "RIESGOS VARIOS",
//                     "poliza": 171588,
//                     "importe": 14314.73,
//                     "mail": "dgomez@vgpower.com.ar",
//                     "id_compania": 61,
//                     "id_user": "66",
//                     "id_seccion": 7,
//                     "id_medio_pago": 0,
//                     "telefono": "1565555303",
//                     "dni": "32714950",
//                     "id_carga_masiva": 1739465160803,
//                     "producto": 21,
//                     "id_operador_asignado": 286,
//                     "updatedAt": "2025-02-13T16:46:00.847Z",
//                     "createdAt": "2025-02-13T16:46:00.847Z"
//                 }
//             ]
//         }
//     },
//     "sin_cargar": {
//         "rechazos_pendientes": {
//             "descripcion": "Rechazo no ingresado, existe como pendiente en la base de datos",
//             "data": []
//         },
//         "rechazos_no_web": {
//             "descripcion": "Rechazo no ingresado, la poliza asociada figura como no web",
//             "data": []
//         },
//         "poliza_sin_datos": {
//             "descripcion": "Rechazo no ingresado, no existen datos asociados a la poliza ingresada",
//             "data": [
//                 {
//                     "id_selection": 7,
//                     "poliza": 171586,
//                     "compania": null,
//                     "nombre": null,
//                     "mail": null,
//                     "dni": null,
//                     "compania_nombre": null,
//                     "medio_pago": "efectivo",
//                     "medio_pago_id": null,
//                     "ramo": null,
//                     "id_seccion": null,
//                     "asoc_ref": null,
//                     "respuesta": "sin datos",
//                     "operacion": null,
//                     "producto": null,
//                     "telefono": null,
//                     "detalle": "No se encontró la póliza en gys2_polizas",
//                     "repetida": false,
//                     "importe_nuevo": null,
//                     "importe": 1149,
//                     "id_reject": null,
//                     "checked": false,
//                     "id_carga_masiva": 1739465160803
//                 },
//                 {
//                     "id_selection": 8,
//                     "poliza": 1700997,
//                     "compania": 61,
//                     "aseg": 206831,
//                     "nombre": "MORALES SANTIAGO",
//                     "es_web": 1,
//                     "id_medio_pago": 0,
//                     "medio_pago": "efectivo",
//                     "mail": "santidm.22011993@gmail.com\n",
//                     "telefono": "1565555303",
//                     "dni": "37533933",
//                     "cuit": "",
//                     "id_compania": 61,
//                     "compania_nombre": "PROVINCIA SEGUROS S.A.",
//                     "ramo": "ACCIDENTES PERSONALES",
//                     "id_seccion": 12,
//                     "id_colaborador": 2272,
//                     "colaborador": "SEGUROWEB",
//                     "email_colaborador": "INFO@GYSSRL.COM.AR",
//                     "ramopoliza": "121700997",
//                     "repetida": false,
//                     "operacion": 329951,
//                     "producto": 3,
//                     "respuesta": "sin datos",
//                     "detalle": "correo con formato invalido o inexitente",
//                     "importe_nuevo": null,
//                     "importe": 474,
//                     "id_reject": null,
//                     "checked": false,
//                     "id_carga_masiva": 1739465160803
//                 },
//                 {
//                   "id_selection": 1,
//                   "poliza": 10583620,
//                   "compania": 61,
//                   "aseg": 223758,
//                   "nombre": "GERMAN EZEQUIEL",
//                   "es_web": 0,
//                   "id_medio_pago": 0,
//                   "medio_pago": "efectivo",
//                   "mail": "",
//                   "telefono": "",
//                   "dni": "0",
//                   "cuit": "",
//                   "id_compania": 61,
//                   "compania_nombre": "PROVINCIA SEGUROS S.A.",
//                   "ramo": "AUTOMOTORES",
//                   "id_seccion": 4,
//                   "id_colaborador": 1937,
//                   "colaborador": "PARA ASIGNAR COLABORADOR",
//                   "email_colaborador": "jmareque@gyssrl.com.ar",
//                   "ramopoliza": "410583620",
//                   "repetida": false,
//                   "respuesta": "sin datos",
//                   "detalle": "No tiene un documento para recuperar más datos",
//                   "id_reject_prev": 54394,
//                   "veces_notificado": 1,
//                   "createdAt": "2025-01-28T13:59:18.000Z",
//                   "updatedAt": "2025-02-12T18:17:29.000Z",
//                   "estado_anterior": "notificado",
//                   "importe_anterior": 88586.7,
//                   "arrastre": 1,
//                   "importe_nuevo": 232399.91,
//                   "importe": 320986.61,
//                   "id_reject": null,
//                   "checked": true,
//                   "id_carga_masiva": 1739401384134
//               }
//             ]
//         },
//         "rechazo_plazo_menor": {
//             "descripcion": "Rechazo no ingresado, la poliza se asoció a un rechazo hace menos de 20 dias",
//             "data": []
//         },
//         "rechazos_no_resueltos": {
//             "descripcion": "Rechazo no ingresado, no se resolvió ni se derivó",
//             "data": []
//         }
//     },
//     "estadisticaGeneral": {
//         "solicitudes": 8,
//         "aceptadas": 6,
//         "rechazadas": 2
//     }
// }
  const[resultadoCargaRechazos, setResultadoCargaRechazos] = useState({})

  // Custom Hooks
  const {
    inputValues,
    handleChange,
    handleChangeExternal,
    handleValidationAsync,
    formErrors,
    resetForm,
  } = useForm(handleSubmit, null, cargarSiniestrosForm);

  const formManual = useForm(
    handleSubmitRechazoManual,
    subirRechazoManualReglas,
    subirRechazoManualForm
  );

  let {
    inputValues: inputValuesUpdate, 
    handleChange: handleChangeUpdate, 
    handleValidation: handleValidationUpdate, 
    formErrors: formErrorsUpdate,
    resetForm: resetFormUpdate
  } = useForm(
    sendDataUpdateSelected,
    subirRechazoManualUpdateReglas,
    subirRechazoManualUpdateForm
  );


  async function handleSubmitRechazoManual() {
    setIdCompania(parseInt(formManual.inputValues.id_compania))
    precargarFormulario();
  }

  // no está usando el input del formulario
  async function handleSubmit() {
    console.error("procesando");
  }


  const [inputsManual, setInputsManual] = useState({
    poliza: "",
    importe: "",
    motivo: "",
    comentario: "",
  });

  let tabs = [
    {
      name: "Por Archivo",
    },
    {
      name: "Manual",
    },
  ];

  ///////////////////////////////////////////////////////////// FUNCIONES


  // confierte un archivo en un array de objetos para ingrear al crm
  // { poliza,importe,motivo,comentario }
  const readFileToArray = (fileinput, formato) => {
    if (fileinput) {
      let fileInCSV = null;
      const reader = new FileReader();
      reader.onload = function (e) {
        if (formato !== "GENERICO") {
          // si se selecciona un formato -> pasar el xlsx primero a csv
          fileInCSV = fileinput;
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          // Selecciona la primera hoja
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          // Convierte la hoja a CSV
          fileInCSV = XLSX.utils.sheet_to_csv(worksheet, {
            raw: false,
            defval: null,
            FS: ";",
          });
        } else {
          fileInCSV = reader.result;
        }
        const { formated, errores, length, incorrectFormat } = formaterObject(
          fileInCSV,
          formato
        );

        setErrorGeneral(incorrectFormat);
        setErrorsFormatingRows(errores || []);
        setFileDataToSend(formated || []);
        setLengthFile(length);
      };

      // si es generico ya lo lee como csv
      formato === "GENERICO"
        ? reader.readAsText(fileinput)
        : reader.readAsArrayBuffer(fileinput);
    }
  };

  const preCargarCsv = async () => {
    changeLoadingText("Obteniendo datos...");
    setLoading(true);
    modalCarga.handleModal();
    setModo("create");
    try {
      // pide la data de las polizas
      await setNewArrayRechazos(fileDataToSend,idCompania);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const preCargarCsvToUpdate = async () => {
    changeLoadingText("Obteniendo datos...");
    setLoading(true);
    modalCarga.handleModal();
    setModo("update");
    try {
      // pide la data de las polizas
      await setNewArrayRechazos(fileDataToSend, idCompania, { toUpdate: true });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const sendDataSelected = async (rechazos,operadorAsignado) => {
    setLoading(true);
    try {
          const res = await sendData(rechazos, userInfo.id_usuario, operadorAsignado);
          console.error('REESSSSSULT', res)
          setResultadoCargaRechazos(res.data)
        // RENDERIZAR RESPUESTA
        //console.error('RESP CARGA FORM Y MASIVA', res)
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setSelection([]);
      setFileDataToSend([]);
      setIdCompania(null);
      setInputsManual({ poliza: "", importe: "", comentario: "", motivo: "" });
      resetForm();
      setErrorGeneral(null);
      setSuccess(true);
      setmedioContacto("");
      setOperadorAsignado(null)
    }
  };

  async function sendDataUpdateSelected(){
    setLoading(true);
    try {
        if( inputValuesUpdate.idNuevoEstado == 11 && inputValuesUpdate.medioContacto==='email') {
          // si es notificacion por mail -> notifica masivamente
          let rechazos = selection.filter(rej => rej.checked)
          rechazos = rechazos.map(rej => {
            rej.id = rej.id_reject_prev;
            return rej;
          })
          // la notifacion masiva automaticamente cambia el estado
          const result = await sendMailManually( rechazos, userInfo.id_usuario)
        } else {
          const res = await updateMultipleRejects(
            {
              rejects: selection,
              id_compania: idCompania,
              new_state: inputValuesUpdate.idNuevoEstado,
              via_contacto: inputValuesUpdate.medioContacto,
            },
            userInfo.id_usuario
          );
          // RENDERIZAR RESPUESTA
          //console.error('RESP UPDATE',res)
        }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setSelection([]);
      setFileDataToSend([]);
      setIdCompania(null);
      resetForm();
      setErrorGeneral(null);
      setSuccess(true);
      setmedioContacto("");
      resetFormUpdate()
      //modalCarga.handleModal() // cierra y muestra el resumen
    }
  };


  // pide los archivos para la carga individual de un rechazo
  const precargarFormulario = async () => {
    setLoading(true);
    modalCarga.handleModal();
    // setIdCompania(parseInt(formManual.inputValues.id_compania))
    changeLoadingText("Obteniendo datos...");
    setModo("create");
    try {
      const cargamanual = await setNewArrayRechazos(fileDataToSend,formManual.inputValues.id_compania, null, true);
      console.error('seeeeel',cargamanual)
      if(cargamanual) {
        setSelection([])
        setCargaManualCompleta(true)
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const setNewArrayRechazos = async (fileDataToSend,idCompania,toUpdate,manualform) => {
    // quita las polizas repetidas
    let polizas = [...new Set(fileDataToSend.map((d) => parseInt(d.poliza)))];
    try {
      setLoading(true);
      // trae los datos asociados a los numeros de polizas ingresados por csv
      const { data } = await getInforPolizas(polizas, idCompania, toUpdate);
      const { data: sections } = data;
      console.error('RES', sections)
      setSections(sections);
      let idselection = 1;
      let cargamanual = true;
      for (const listas in sections) {
        
        if (listas) {
          let formated = {};
          let temp = [];
          sections[listas].data.forEach((row) => {
            const match = fileDataToSend.find(
              (d) => parseInt(d.poliza) === parseInt(row.poliza)
            );
            // const {nombre, poliza, mail, id_compania, id_seccion, respuesta,detalle, producto, telefono, dni, id, estado_anterior} = row;
            formated = {
              id_selection: idselection,
              ...row,
              importe_nuevo: row.importe_anterior? match.importe : null,
              importe: row.importe_anterior? row.importe_anterior + match.importe : match.importe, // importe total si hay un acumulado
              medio_pago: row.medio_pago || "efectivo",
              id_reject: row.id || null,
              checked: sections[listas].checkeable,
            };


            formated.importe = row.importe? row.importe : formated.importe;

            if (
              !match.importe ||
              match.importe === "" ||
              isNaN(match.importe) ||
              match.importe === 0
            ) {
              formated.checked = false;
              formated.respuesta = "el importe es un campo requerido";
              if (sections[listas].data.length === 1) {
                sections[listas].descripcion =
                  "El importe es un campo requerido";
              }
            }

            idselection += 1;
            temp.push(formated);
            if (formated.checked ) cargamanual = false;
            setSelection((prev) => [...prev, formated]);
          });
          sections[listas].data = temp;
        }
      }
      return manualform? cargamanual: null;
      // setInformeGeneralCarga(res.data) -> mostrar datos
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleCheck = (id_selection) => {
    let selectAux = [...selection];
    for (const aux of selectAux) {
      if (aux.id_selection === id_selection) aux.checked = !aux.checked;
    }
    setSelection(selectAux);
  };

  const tableCheck = (e, data) => {
    let selectAux = [...selection];
    if (e.target.checked) {
      // si se selecciona
      for (const itemData of data) {
        for (const aux of selectAux) {
          if (aux.id_selection === itemData.id_selection) {
            aux.checked = true;
          }
        }
      }
    } else {
      // si se deselecciona
      for (const itemData of data) {
        for (const aux of selectAux) {
          if (aux.id_selection === itemData.id_selection) {
            aux.checked = false;
          }
        }
      }
    }
    setSelection(selectAux);
  };

  useEffect(() => {
    if (!modalCarga.isModalOpen) {
      setModo("");
    }
  }, [modalCarga]);

  const closeModal = () => {
    setModo("");
    modalCarga.setIsModalOpen(false);
    setSuccess(false);
  };


  /////////////////////////////////////////////////////////// EFECTOS

  // formulario manual para la carga de un rechazo
  // reemplazar por el hook de un formulario
  useEffect(() => {
    if (formManual.inputValues.poliza && formManual.inputValues.importe) {
      setFileDataToSend([
        {
          poliza: parseInt(formManual.inputValues.poliza),
          importe: parseFloat(formManual.inputValues.importe),
        },
      ]);
    }
  }, [formManual.inputValues.poliza, formManual.inputValues.importe]);


  // al cargar la vista trea las companias y estados
  useEffect(() => {
    const getData = async () => {
      console.log("VAMOOOOOOOOO")
      try {
        const companias = await getCompanias();
        const estados = await getEstados();
        const operadoresBack = await getOperadores();
        const seccionesBack = await getSecciones();
        const mediosPagoBack = await getMediosPago();
        setCompanias(companias);
        setEstados(estados);
        setOperadoresBack(operadoresBack);
        setRamos(seccionesBack)
        setMediosPago(mediosPagoBack)
      } catch (error) {
        console.log(error)
      }

    }
    getData()

  }, []);

  // cuando se selecciona un archivo y se cambia el formato en el select
  useEffect(() => {
    setErrorsFormatingRows([]);
    setFileDataToSend([]);
    setErrorGeneral(null);
    setSelection([]);
    if (inputValues?.file?.image) {
      if (parseInt(idFormato) === 1) {
        if (inputValues?.file?.image.name.includes(".csv")) {
          readFileToArray(inputValues?.file.image, "GENERICO");
        } else {
          setErrorGeneral("Formato incorrecto");
        }
      }
      // NO ES NECESARIO POR EL MOMENTO
      if (parseInt(idFormato) === 2) {
        readFileToArray(inputValues?.file.image, "FEDPAT");
      }
      if (parseInt(idFormato) === 3) {
        readFileToArray(inputValues?.file.image, "FEDPAT_EFECTIVO");
      }
      if (parseInt(idFormato) === 4) {
        readFileToArray(inputValues?.file.image, "PRUDENCIA");
      }
    }
  }, [inputValues, idFormato]);

  useEffect(() => {
    if (!modalCarga.isModalOpen) {
      // si se cierra el modal se quita la seleccion
      setSelection([]);
      setSections([]);
      setModo(null);
      resetFormUpdate()
      setOperadorAsignado(null)
      setCargaManualCompleta(false)
    }
  }, [modalCarga.isModalOpen]);


  return (
    <div>
      <div>
        {modalCarga.isModalOpen ? (
          <ModalCargarRechazos2
            isModalOpen={modalCarga.isModalOpen}
            handleModal={modalCarga.handleModal}
            setIsModalOpen={modalCarga.setIsModalOpen}
            modal={modalCarga}
            loading={loading}
            selection={selection}
            loadingText={loadingText}
            sections={sections}
            tableCheck={tableCheck}
            handleCheck={handleCheck}
            sendData={sendDataSelected}
            modo={modo}
            estados={estados}
            setIdNuevoEstado={setIdNuevoEstado}
            setmedioContacto={setmedioContacto}
            success={success}
            closeModal={closeModal}
            medioContacto={medioContacto}
            idNuevoEstado={idNuevoEstado}
            sendDataUpdateSelected={sendDataUpdateSelected}
            handleChangeUpdate={handleChangeUpdate}
            handleValidationUpdate={handleValidationUpdate}
            formErrorsUpdate={formErrorsUpdate}
            inputValuesUpdate={inputValuesUpdate}
            userInfo={userInfo}
            operadorAsignado={operadorAsignado}
            setOperadorAsignado={setOperadorAsignado}
            operadoresBack={operadoresBack}
            cargaManualCompleta={cargaManualCompleta}
            companias={companias}
            id_compania={formManual.inputValues.id_compania}
            ramos={ramos}
            mediosPago={mediosPago}
            initialDataCargaCompleta={formManual.inputValues}
            resultadoCargaRechazos={resultadoCargaRechazos}
          />
        ) : null}
        <Layout>
        <NewWrapper>
          <NewInner>
            <Container>
              <div>
                <BackButton url={"/Rechazos2"} text="Cobranzas" />
                <NewTitlePageSection
                  title={`Gestionar Cobranzas`}
                  description={`Alta y actualización`}
                ></NewTitlePageSection>
              </div>
              <NewTabs
                tabs={tabs}
                tabSelected={tabSelected}
                handleTab={handleTab}
              />
              {tabSelected === 0 && (
                <>
                  <Subtitle
                    icon={<FiFile />}
                    title={`Carga de archivo`}
                    description={`Cargue el archivo para continuar`}
                    disableDivider={true}
                  />
                  <Divider />
                  {!inputValues.file && (
                    <Grid colGap={21} rowGap={21} narrow={false}>
                      <Col desktop={12}>
                        <InputFile
                          type={"file"}
                          name={"file"}
                          text={"Seleccione un archivo para comenzar"}
                          labelName={""}
                          required={true}
                          multiple={false}
                          file={inputValues.file}
                          onChange={handleChangeExternal}
                        />
                      </Col>
                    </Grid>
                  )}
                  {inputValues.file && (
                    <>
                      <Subtitle
                        icon={<FiFile />}
                        title={inputValues.file.name}
                        description={`Seleccione la compañía con la que desea trabajar`}
                        disableDivider={true}
                      />
                      <Grid colGap={21} rowGap={21} narrow={true}>

                        <Col desktop={3}>
                          <NewInputSelect
                            type={"text"}
                            name={"id_compania"}
                            labelName={"Compañia"}
                            id={"compania"}
                            options={companias}
                            placeholder={"Compañia"}
                            onChange={(e) => setIdCompania(e.target.value)}
                          />
                        </Col>
                        <Col desktop={12}>
                          {errorGeneral && <p>{errorGeneral}</p>}
                          {lengthFile > 0 && (
                            <p>Se detectaron {lengthFile} registros</p>
                          )}
                          {errorFormatingRows &&
                            errorFormatingRows.length > 0 && (
                              <div className="errores-container">
                                <span className="errores-titulo">
                                  <span className="icon">
                                    <FiX></FiX>
                                  </span>
                                  {errorFormatingRows.length > 1
                                    ? "Se detectaron errores en el archivo subido"
                                    : "Se detecto un error en el archivo cargado"}
                                  :
                                </span>
                                <p>
                                  <strong>
                                    {errorFormatingRows[0].error}.
                                  </strong>
                                </p>
                                {/* <div>
                            {
                              errorFormatingRows.map( (e, index) => <p><span className="icon"><FiX></FiX></span>{e.error} </p>)
                            }
                          </div> */}
                              </div>
                            )}
                        </Col>
                      </Grid>
                      <Divider />
                      <Subtitle
                        icon={<FiShare />}
                        title={"Opciones"}
                        disableDivider={true}
                      />
                      <Grid colGap={21} rowGap={21} narrow={false}>
                        {idCompania && fileDataToSend?.length > 0 && (
                          <>
                            <Col desktop={2}>
                              <NewButton
                                backgroundColor={true}
                                onClick={preCargarCsv}
                                disabled={loading}
                              >
                                <FiUploadCloud /> Dar de alta
                              </NewButton>
                            </Col>
                            <Col desktop={2}>
                              <NewButton
                                backgroundColor={true}
                                onClick={preCargarCsvToUpdate}
                                disabled={loading}
                              >
                                <FiEdit3 /> Actualizar
                              </NewButton>
                            </Col>
                            <Col desktop={2}>
                              <NewButton
                                backgroundColor={true}
                                onClick={preCargarCsvToUpdate}
                                disabled={true}
                              >
                                <FiUserCheck /> Asignar
                              </NewButton>
                            </Col>
                          </>
                        )}
                        <Col desktop={3}>
                          <NewButton
                            backgroundColor={false}
                            textColor={true}
                            borderColor={true}
                            onClick={resetForm}
                            disabled={loading}
                          >
                            <FiRefreshCcw /> Cambiar de archivo
                          </NewButton>
                        </Col>
                      </Grid>
                    </>
                  )}
                </>
              )}
              {tabSelected === 1 && (
                <>
                  <Subtitle
                    icon={<FiFile />}
                    title={"Formulario"}
                    description={`Ingresar un nuevo rechazo de forma manual`}
                    disableDivider={true}
                  />
                  <Grid colGap={21} rowGap={21} narrow={false}>
                    <Col desktop={3}>
                      <NewInputBasic
                        type={"text"}
                        name={"poliza"}
                        labelName={"Póliza"}
                        placeholder={"1234"}
                        id={"poliza"}
                        onChange={formManual.handleChange}
                        value={formManual.inputValues.poliza}
                        required
                      />
                      {formManual.formErrors.poliza ? (
                        <ErrorInput>{formManual.formErrors.poliza}</ErrorInput>
                      ) : null}
                    </Col>
                    <Col desktop={3}>
                      <NewInputBasic
                        type={"number"}
                        name={"importe"}
                        labelName={"Importe"}
                        placeholder={"1234"}
                        id={"importe"}
                        onChange={formManual.handleChange}
                        value={formManual.inputValues.importe}
                      />
                      {formManual.formErrors.importe ? (
                        <ErrorInput>{formManual.formErrors.importe}</ErrorInput>
                      ) : null}
                    </Col>
                    <Col desktop={3}>
                      <NewInputSelect
                        type={"number"}
                        name={"id_compania"}
                        labelName={"Compañia"}
                        id={"id_compania"}
                        options={companias}
                        placeholder={"Compañia"}
                        onChange={formManual.handleChange}
                      />
                      {formManual.formErrors.id_compania ? (
                        <ErrorInput>
                          {formManual.formErrors.id_compania}
                        </ErrorInput>
                      ) : null}
                    </Col>
                  </Grid>
                  <Grid colGap={21} rowGap={21} narrow={false}>
                    <Col desktop={3}>
                      <NewButton
                        backgroundColor={true}
                        onClick={formManual.handleValidation}
                        disabled={loading}
                      >
                        <FiEdit3 /> Cargar Rechazo
                      </NewButton>
                    </Col>
                  </Grid>
                </>
              )}
            </Container>
          </NewInner>
        </NewWrapper>
        </Layout>
      </div>
    </div>
  );
};

export default GestionRechazos;
