import React from "react";
import Modal from "../../../Modal";
import { RechazoContainer } from "./styled";
import { Col, Grid } from "../../../Grid";
import { useForm } from "../../../../hooks/useForm";
import ErrorInput from "../../../ErrorInput";
import { objetivosReglas } from "../../../../utils/formRules";
import {
  objetivoPremiosProducto,
  objetivosForm,
} from "../../../../utils/formInitialValues";
import { useLoading } from "../../../../hooks/useLoading";
import ModalSuccess from "../../../ModalSuccess";
import useModal from "../../../../hooks/useModal";
import axiosRequest from "../../../../utils/axiosConfig";
import NewButton from "../../../NewButton";
import NewTitle from "../../../NewTitle";
import NewInputBasic from "../../../NewInputBasic";
import { useEffect } from "react";
import { meses } from "../../../../constants/data";
import Divider from "../../../Divider";
import { useState } from "react";
import Subtitle from "../../../Subtitle";
import {
  FiBox,
  FiDelete,
  FiDollarSign,
  FiEdit,
  FiPlus,
  FiTarget,
  FiTrash,
  FiTrash2,
} from "react-icons/fi";
import GoalConverter from "../../../GoalConverter";
import Success from "../../../Success";
import NewInputSelect from "../../../NewInputSelect";
import formatSelectData from "../../../../utils/formatSelectData";
import IconButton from "../../../IconButton";

const ModalObjetivos = ({
  modal,
  vendedor,
  objetivos,
  productosObjetivos,
  refreshData,
}) => {
  const { isModalOpen, handleModal } = modal;
  const {
    inputValues,
    handleChange,
    formErrors,
    handleValidation,
    completeForm,
  } = useForm(handleSubmit, objetivosReglas, objetivosForm);
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const mes = new Date().getMonth();
  const anio = new Date().getFullYear();
  const [success, setSuccess] = useState(false);
  const [productos, setProductos] = useState([]);
  const [objetivosProductos, setObjetivosProductos] =
    useState(productosObjetivos);
  const [mode, setMode] = useState("create");
  const [showAddProductGoalForm, setShowAddProductGoalForm] = useState(false);
  const [indexObjetivo, setIndexObjetivo] = useState(null)
  const [objetivosProductoEliminar, setObjetivosProductoEliminar] = useState([])
  const productoForm = useForm(null, {}, objetivoPremiosProducto);

  const { loading, setLoading, changeLoadingText } = useLoading(false);
  const exitoModal = useModal();

  useEffect(() => {
    const getProductos = async () => {
      try {
        const productos = await axiosRequest.get("/premios/productos");
        const productosList = formatSelectData(
          productos.data,
          "numero",
          "descripcion",
          "numero"
        );
        setProductos(productosList);
      } catch (error) {
        console.log(error);
      }
    };
    getProductos();
  }, []);

  const agregarObjetivoProducto = () => {
    setObjetivosProductos((prev) => [...prev, productoForm.inputValues]);
    productoForm.resetForm();
  };

  const eliminarObjetivoProducto = (objetivo) => {
    const filterObjetivo = objetivosProductos.filter(item => item.id_objetivo_producto !== objetivo.id_objetivo_producto)
    setObjetivosProductos(filterObjetivo)
    setObjetivosProductoEliminar(prev => [...prev, objetivo.id_objetivo_producto])
  }

  const agregarNuevoObjetivoProducto = () => {
    setMode("create");
    setShowAddProductGoalForm(true)
    productoForm.resetForm()
  }

  const editObjetivoProducto = (objetivo, index) => {
    productoForm.completeForm(objetivo);
    setMode("edit");
    setShowAddProductGoalForm(true)
    setIndexObjetivo(index)
  };

  const editarObjetivoProducto = () => {
    console.log(indexObjetivo)
    const newData = objetivosProductos.map((item, index) => {
      if(index === indexObjetivo) {
        return productoForm.inputValues
      } else {
        return item
      }
    })
    setObjetivosProductos(newData)
    setShowAddProductGoalForm(false)
    setIndexObjetivo(null)
    setMode("create");
    productoForm.resetForm()
  }

  useEffect(() => {
    if (objetivos) {
      const { operaciones, prima } = objetivos;
      completeForm({ operaciones, prima });
    }
  }, [objetivos]);

  const handleObjetivosProductos = async () => {
    if(objetivosProductoEliminar.length > 0){
      objetivosProductoEliminar.forEach(async (id_objetivo_producto) => {
        await axiosRequest.delete(`/premios/vendedor_objetivos_productos/${id_objetivo_producto}`);
      })
    }
    if(productosObjetivos) {
      await axiosRequest.put(`/premios/vendedor_objetivos_productos/${vendedor.id_vendedor}/${mes}/${anio}`, objetivosProductos);
    }
    if (objetivosProductos && !productosObjetivos) {
      await axiosRequest.post(`/premios/vendedor_objetivos_productos/${vendedor.id_vendedor}/${mes}/${anio}`, objetivosProductos);
    }
  }

  async function handleSubmit() {
    setLoading(true);
    changeLoadingText("Creando objetivos...");
    await handleObjetivosProductos()
    try {
      if (objetivos) {
        const update = await axiosRequest.put(
          `/premios/vendedor_objetivos/${vendedor.id_vendedor}`,
          inputValues
        );
        if (update.status === 200) {
          const historial = {
            mensaje: `${
              user.nombre_operador
            } editó los objetivos del mes de ${meses
              .find((item) => item.value === mes)
              .label.toLowerCase()} para ${vendedor.vendedor}. Operaciones: ${
              inputValues.operaciones
            }, Prima: $${inputValues.prima}`,
            dia: new Date(),
            id_vendedor: vendedor.id_vendedor,
            id_premio: null,
            id_usuario: user.id_operador,
            id_tipo_modificacion: 2,
          };
          await axiosRequest.post(
            `/premios/historial_modificaciones`,
            historial
          );
          await refreshData();
          setSuccess(true)
          setLoading(false);
        }
      } else {
        const crear = await axiosRequest.post(
          `/premios/vendedor/objetivos/${vendedor.id_vendedor}`,
          inputValues
        );
        if (crear.status === 200) {
          // HISTORIAL MODIFICACIONES
          const historial = {
            mensaje: `${
              user.nombre_operador
            } creó los objetivos del mes de ${meses
              .find((item) => item.value === mes)
              .label.toLowerCase()} para ${vendedor.vendedor}. Operaciones: ${
              inputValues.operaciones
            }, Prima: $${inputValues.prima}`,
            dia: new Date(),
            id_vendedor: vendedor.id_vendedor,
            id_premio: null,
            id_usuario: user.id_operador,
            id_tipo_modificacion: 2,
          };
          await axiosRequest.post(
            `/premios/historial_modificaciones`,
            historial
          );
          await refreshData();
          setSuccess(true)
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <div>
      <ModalSuccess
        isSuccessPopUpOpen={exitoModal.isModalOpen}
        setIsSuccessPopUpOpen={exitoModal.handleModal}
        popUpMessage={"¡Objetivos cargados!"}
      />
      <Modal isModalOpen={isModalOpen} setIsModalOpen={handleModal}>
        <RechazoContainer>
          <div className="header-container">
            <NewTitle>Objetivos</NewTitle>
            <p>
              Completá los objetivos de {vendedor.vendedor} para el mes actual
            </p>
          </div>
          <Divider />
          {!success ? (
            <>
              <div className="data-container">
                <div className="conversor-prima-container">
                  <Subtitle
                    icon={<FiDollarSign />}
                    title={"Conversor de prima"}
                    description={
                      "Convertí un valor de prima anual para obtener un nuevo valor de objetivo"
                    }
                    disableDivider={true}
                  />
                  <GoalConverter />
                </div>
                <Divider />
                <Subtitle
                  icon={<FiTarget />}
                  title={"Objetivos generales"}
                  description={"Objetivos de operaciones y prima en el mes"}
                  disableDivider={true}
                />
                <Grid colGap={21} rowGap={21} narrow={true}>
                  <Col desktop={6}>
                    <NewInputBasic
                      type="number"
                      labelName="Operaciones"
                      name="operaciones"
                      placeholder="100"
                      onChange={handleChange}
                      value={inputValues.operaciones}
                    />
                    {formErrors.operaciones && (
                      <ErrorInput>{formErrors.operaciones}</ErrorInput>
                    )}
                  </Col>
                  <Col desktop={6}>
                    <NewInputBasic
                      type="number"
                      labelName="Prima"
                      name="prima"
                      placeholder="1000000"
                      onChange={handleChange}
                      value={inputValues.prima}
                    />
                    {formErrors.prima && (
                      <ErrorInput>{formErrors.prima}</ErrorInput>
                    )}
                  </Col>
                </Grid>
                <Divider />
                <Subtitle
                  icon={<FiBox />}
                  title={"Objetivos por producto"}
                  description={
                    "Especificá el porcentaje del objetivo para cada producto"
                  }
                  disableDivider={true}
                />
                {productosObjetivos.length === 0 ? (
                  <div className="container-form-objetivos-productos">
                    <NewInputSelect
                      type="number"
                      labelName="Producto"
                      name="producto"
                      placeholder="Producto"
                      onChange={productoForm.handleChange}
                      value={productoForm.inputValues.producto}
                      options={productos}
                    />
                    <NewInputBasic
                      type="number"
                      labelName="Cantidad"
                      name="operaciones"
                      placeholder="10"
                      onChange={productoForm.handleChange}
                      value={productoForm.inputValues.operaciones}
                    />
                    <NewButton
                      borderColor={true}
                      textColor={true}
                      onClick={agregarObjetivoProducto}
                    >
                      Añadir
                    </NewButton>
                  </div>
                ) : null}
                {/* <Grid colGap={21} rowGap={21} narrow={true}>
              <Col desktop={4}>
                <NewInputSelect
                  type="number"
                  labelName="Producto"
                  name="producto"
                  placeholder="Producto"
                  onChange={productoForm.handleChange}
                  value={productoForm.inputValues.producto}
                  options={productos}
                />
              </Col>
              <Col desktop={4}>
                <NewInputBasic
                  type="number"
                  labelName="Cantidad"
                  name="operaciones"
                  placeholder="10"
                  onChange={productoForm.handleChange}
                  value={productoForm.inputValues.operaciones}
                />
              </Col>
              <Col desktop={4}>
                <NewButton borderColor={true} textColor={true} onClick={agregarObjetivoProducto}>Añadir</NewButton>
              </Col>
            </Grid> */}
                {objetivosProductos.length > 0 ? (
                  <div className="container-objetivos-productos-agregados">
                    <h4>Lista de objetivos por productos</h4>
                    {objetivosProductos.map((item, index) => {
                      return (
                        <div className="objetivo-producto">
                          <div className="objetivo-producto-informacion">
                            <span>
                              <FiTarget />
                            </span>
                            {
                              productos.find(
                                (producto) => producto.value == item.producto
                              )?.label
                            }{" "}
                            - {item.operaciones} operaciones
                          </div>
                          <div className="objetivo-producto-botones">
                            <span
                              onClick={() => editObjetivoProducto(item, index)}
                            >
                              <FiEdit />
                            </span>
                            <span onClick={() => eliminarObjetivoProducto(item)}>
                              <FiTrash2 />
                            </span>
                          </div>
                        </div>
                      );
                    })}
                    <div
                      className="objetivo-producto-crear"
                      onClick={() =>
                        agregarNuevoObjetivoProducto()
                        
                      }
                    >
                      <span>
                        <FiPlus />
                      </span>
                      Añadir nuevo objetivo de producto
                    </div>
                  </div>
                ) : null}
                {showAddProductGoalForm ? (
                  <div className="container-form-objetivos-productos">
                    <NewInputSelect
                      type="number"
                      labelName="Producto"
                      name="producto"
                      placeholder="Producto"
                      onChange={productoForm.handleChange}
                      value={productoForm.inputValues.producto}
                      options={productos}
                    />
                    <NewInputBasic
                      type="number"
                      labelName="Cantidad"
                      name="operaciones"
                      placeholder="10"
                      onChange={productoForm.handleChange}
                      value={productoForm.inputValues.operaciones}
                    />
                    <NewButton
                      borderColor={true}
                      textColor={true}
                      onClick={mode === "edit" ? editarObjetivoProducto : agregarObjetivoProducto}
                    >
                      {mode === "edit" ? "Editar" : "Añadir"}
                    </NewButton>
                  </div>
                ) : null}
              </div>
              <Divider />
              <div className={"actionButtonsCont"}>
                <div className="buttonsCont">
                  <NewButton
                    backgroundColor={false}
                    textColor={true}
                    borderColor={true}
                    onClick={handleModal}
                  >
                    Cancelar
                  </NewButton>
                  <NewButton
                    backgroundColor={true}
                    onClick={handleValidation}
                    disabled={loading}
                  >
                    {loading
                      ? `${objetivos ? "Actualizando" : "Creando"} objetivos...`
                      : `${objetivos ? "Actualizar" : "Crear"} objetivos`}
                  </NewButton>
                </div>
              </div>
            </>
          ) : (
            <Success
              title={`¡Objetivos ${objetivos ? "actualizados" : "creados"}!`}
              description={`Los objetivos fueron ${
                objetivos ? "actualizados" : "creados"
              } correctamente`}
              handleModal={handleModal}
            />
          )}
        </RechazoContainer>
      </Modal>
    </div>
  );
};

export default ModalObjetivos;
