export const rolesLandings = [1, 4, 15]

export const rolesEstadisticasProductoCanal = [1,15,4,13,17]

export const rolesObjetivos = [1,15,4]

export const rolesCapacitaciones = [1,15,4]

export const rolesCampañas = [1,15,4]

export const rolesFedPat = [1,15,4]

export const rolesFichajes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 23, 24] 

export const rolesSynergys = [1,15,4] 

export const rolesSiniestros = [1,15,4, 7, 20] 

export const rolesConsultas = [1,15,4] 

export const rolesStorageApi = [1,15,4,10,13,17]

export const rolesEstadisticas = [1,15,4]

export const rolesClientes = [1,15,4]

export const rolesEmisiones = [1,15,4,6,13,14,17,18, 7]

export const rolesUsuarios = [1,15,4]

export const rolesRechazos = [1,15,4,5,8, 23]

export const rolesHistorialSolicitudes = [1,15,4,10,18,13,7, 22, 24]

export const rolesSolicitudEmision = [1,15,4,14,6, 7,13]

export const rolesRechazadas = [1,15,4]

export const rolesCargaEdicionSolicitud = [1,15,4,10,18,7,22,24]

export const rolesColaboradores = [1, 19, 15, 4]

export const rolesPremios = [1, 7, 13, 15, 16, 22 , 24]

