import React from "react";
import { Col, Grid } from "../../../Grid";
import NewInputSelect from "../../../NewInputSelect";
import { anios, meses } from "../../../../constants/data";
import NewTable from "../../../NewTable";
import { objetivosVendedores } from "../../../../utils/tableData";
import { useTable } from "../../../../hooks/useTable";
import useModal from "../../../../hooks/useModal";
import ModalConversorPrima from "../../../Modals/Premios/ModalConversorPrima";
import NewButton from "../../../NewButton";
import { Container } from "./styled";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CardNumber from "../../../CardNumber";
import Subtitle from "../../../Subtitle";
import { FiDollarSign, FiTarget } from "react-icons/fi";
import Divider from "../../../Divider";
import SpinnerLoading from "../../../SpinnerLoading";

const PremiosObjetivosTab = ({ data }) => {
  const { mes, setMes, objetivos, loadingObjetivos, anio, setAnio } = data;
  const location = useLocation();
  const tableData = objetivosVendedores(location);
  const { order, handleTableOrder } = useTable();
  const conversorPrimaModal = useModal();
  const formatter = new Intl.NumberFormat("es-ES", {});

  return (
    <>
      <Container>
        {conversorPrimaModal.isModalOpen ? (
          <ModalConversorPrima modal={conversorPrimaModal} />
        ) : null}
        <Grid colGap={60} rowGap={21} narrow={false}>
          <Col desktop={2}>
            <NewInputSelect
              name={"mes"}
              labelName={"Mes"}
              onChange={(e) => setMes(e.target.value)}
              placeholder={"Mes"}
              options={meses}
              value={mes}
            />
          </Col>
          <Col desktop={2}>
            <NewInputSelect
              name={"anio"}
              labelName={"Año"}
              onChange={(e) => setAnio(e.target.value)}
              placeholder={"Año"}
              options={anios}
              value={anio}
            />
          </Col>
        </Grid>

        <NewButton
          backgroundColor={true}
          onClick={conversorPrimaModal.handleModal}
        >
          Conversor prima
        </NewButton>
      </Container>

      <Divider />

      {objetivos?.total ? (
        <>
          <Subtitle
            icon={<FiDollarSign />}
            title={`Totales Objetivos`}
            description={"Valore totales de objetivos de operaciones y prima"}
            disableDivider={true}
          />
          <Grid colGap={21} rowGap={21} narrow={false}>
            <Col desktop={3}>
              <CardNumber
                title="Total operaciones"
                value={objetivos?.total.operaciones}
              />
            </Col>
            <Col desktop={3}>
              <CardNumber
                title="Total prima"
                value={`$${formatter.format(objetivos?.total.prima)}`}
              />
            </Col>
          </Grid>
        </>
      ) : null}


      {Object.keys(objetivos).length > 0 ?
      <>
            <Divider />
      <Subtitle
            icon={<FiTarget />}
            title={`Objetivos`}
            description={"Lista de objetivos de operaciones y prima para todos los vendedores"}
            disableDivider={true}
          />

      <NewTable
        columns={tableData}
        data={objetivos?.objetivos}
        loading={loadingObjetivos}
        order={handleTableOrder}
        orderParams={order}
      />
      </>
      : <SpinnerLoading text={"Cargando objetivos"}/>}
    </>
  );
};

export default PremiosObjetivosTab;
